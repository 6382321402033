/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DiagramsBlogPostList from "./DiagramsBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden',
    textAlign: 'center',
});

const DiagramsBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183',
                                    padding: '4px',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Diagrams Blog
                            </Typography>
                        </Box>
                        {/* Expanded Content */}
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography
                                variant="h5"
                                sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}
                            >
                                Diagrams Tutorials and Articles
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}
                            >
                                Explore articles and tutorials on creating and using diagrams with NebulaSolver.
                                Learn how to create effective diagrams for various purposes, from system overviews
                                and process flows to organizational charts and technical schematics.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}
                            >
                                Whether you are designing complex control systems, illustrating engineering processes,
                                or visualizing large datasets, diagrams are a powerful way to communicate ideas
                                effectively. These guides will help you harness NebulaSolver’s diagram tools to streamline
                                your workflow and present information in a clear, structured manner.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>

                {/* Blog Post List */}
                <DiagramsBlogPostList />

                {/* Concluding Paragraph */}
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    As our collection of diagram-related content continues to expand, we invite you to explore
                    best practices, tips, and real-world examples. By leveraging diagrams for clarity and
                    comprehension, you’ll transform complex concepts into accessible insights—one illustration at a time.
                </Typography>
            </Box>

            <Helmet>
                <title>How To's - Diagrams Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/diagramsblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on creating and using diagrams with NebulaSolver. Learn how to create effective diagrams for various purposes, from system overviews to process flows and more."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/diagramsblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/diagramsblogposts" />
                <meta property="og:title" content="How To's - Diagrams Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/diagramsblogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on creating and using diagrams with NebulaSolver. Learn how to create effective diagrams for various purposes, from system overviews to process flows and more."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Diagrams Blog Posts" />
                <meta
                    name="twitter:description"
                    content="Explore comprehensive articles and tutorials on creating and using diagrams with NebulaSolver. Learn how to create effective diagrams for various purposes, from system overviews to process flows and more."
                />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default DiagramsBlogPosts;
