/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BodeBlogPostList from "./BodeBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const BodeBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183', // Update arrow color
                                    padding: '4px', // Reduce padding for the button
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Bode Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Bode Plot Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Explore comprehensive guides on Bode Plot analysis that explain the theory behind frequency response,
                                including how to generate plots, interpret gain and phase margins, and evaluate system stability.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Our tutorials cover practical methodologies for analyzing and optimizing control systems. Learn
                                step-by-step techniques to adjust controller parameters and improve performance based on your Bode Plots.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Whether you are a student, researcher, or practicing engineer, these articles provide actionable insights and real-world examples
                                to help you master Bode Plot analysis and apply it effectively in your projects.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <BodeBlogPostList />
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    Stay informed with our extensive collection of Bode Plot tutorials and case studies. Continue exploring to uncover expert tips, innovative analysis strategies, and practical advice that will empower you to optimize your control systems.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Bode Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/bodeblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Bode Plots using NebulaSolver. Learn how to generate, interpret, and optimize your Bode Plot analysis to enhance system performance."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/bodeblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/bodeblogposts" />
                <meta property="og:title" content="How To's - Bode Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/bodeblogposts" />
                <meta property="og:description" content="Explore comprehensive articles and tutorials on Bode Plots using NebulaSolver. Learn how to generate, interpret, and optimize your Bode Plot analysis to enhance system performance." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Bode Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on Bode Plots using NebulaSolver. Learn how to generate, interpret, and optimize your Bode Plot analysis to enhance system performance." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default BodeBlogPosts;
