/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StepResponseBlogPostList from "./StepResponseBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const StepResponseBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183',
                                    padding: '4px',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Step Response Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Step Response Analysis Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Dive into comprehensive guides on Step Response analysis, a critical tool for understanding the dynamic behavior of systems. Learn how to generate and interpret step response plots, which reveal key performance metrics such as rise time, overshoot, settling time, and steady-state error.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Our tutorials provide detailed explanations of control system theory, practical examples, and hands-on tips to help you optimize system performance. Whether you’re troubleshooting a control loop or designing a new system, these articles offer actionable insights into achieving reliable and stable operation.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                With NebulaSolver, you can explore step-by-step techniques and real-world applications that bridge the gap between theoretical analysis and practical implementation. Enhance your understanding of how step responses inform design decisions and contribute to system optimization.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <StepResponseBlogPostList />
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    Our growing collection of step response tutorials is designed to empower engineers and researchers alike. Explore these resources to transform complex control challenges into streamlined, efficient solutions.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Step Response Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/stepresponseblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Step Response analysis using NebulaSolver. Learn how to create and analyze step response plots to optimize system performance and understand dynamic behavior."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/stepresponseblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/stepresponseblogposts" />
                <meta property="og:title" content="How To's - Step Response Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/stepresponseblogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on Step Response analysis using NebulaSolver. Learn how to create and analyze step response plots to optimize system performance and understand dynamic behavior."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Step Response Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on Step Response analysis using NebulaSolver. Learn how to create and analyze step response plots to optimize system performance and understand dynamic behavior." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default StepResponseBlogPosts;
