/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box, Card, CardContent, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from "react-helmet";

import LogicBlogPostList from "../HowTosLogic/LogicBlogPostList";
import BlogPostListPlain from "./BlogPostListPlain";
import MatrixBlogPostList from "../HowTosMatrix/MatrixBlogPostList";
import BodeBlogPostList from "../HowTosBode/BodeBlogPostList";
import NyquistBlogPostList from "../HowTosNyquist/NyquistBlogPostList";
import NicholsBlogPostList from "../HowTosNichols/NicholsBlogPostList";
import RootLocusBlogPostList from "../HowTosRootLocus/RootLocusBlogPostList";
import PoleZeroMapBlogPostList from "../HowTosPoleZeroMap/PoleZeroMapBlogPostList";
import StepResponseBlogPostList from "../HowTosStepResponse/StepResponseBlogPostList";
import ImpulseResponseBlogPostList from "../HowTosImpulseResponse/ImpulseResponseBlogPostList";
import FeedbackControlSystemBlogPostList from "../HowTosFeedbackControlSystem/FeedbackControlSystemBlogPostList";
import FeaBlogPostList from "../HowTosFea/FeaBlogPostList";
import BeamBlogPostList from "../HowTosBeam/BeamBlogPostList";
import ThermoPlotsBlogPostList from "../HowTosThermoPlots/ThermoPlotsBlogPostList";
import BmsBlogPostList from "../HowTosBms/BmsBlogPostList";
import CircuitBlogPostList from "../HowTosCircuit/CircuitBlogPostList";
import ModelBlogPostList from "../HowTosModel/ModelBlogPostList";
import DiagramsBlogPostList from "../HowTosDiagrams/DiagramsBlogPostList";
import GanttBlogPostList from "../HowTosGantt/GanttBlogPostList";
import StocksBlogPostList from "../HowTosStocks/StocksBlogPostList";
import ResumeBlogPostList from "../HowTosResume/ResumeBlogPostList";

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '1rem',
    color: '#9DC183',
    '&:hover': {
        color: '#0056b3',
    },
    cursor: 'pointer',
    textDecoration: 'none'
});

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    height: '240px', // Fixed height for the card
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const BlogPosts = () => {
    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 3,
                    width: '100%' // Ensure full width
                }}
            >
                {/* Top Card Section */}
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography
                            variant="h1"
                            sx={{ textAlign: 'center', marginBottom: 2, color:'#ffc801', fontSize: '2rem' }}
                        >
                            All Blog Posts
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{ textAlign: 'center', marginBottom: 2, color:'#fffffe' }}
                        >
                            All Tutorials and Articles
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ textAlign: 'center', marginBottom: 2, color: '#b9b9b9' }}
                        >
                            Discover in-depth guides and tutorials across various apps including logic solver,
                            equation solver, linear algebra, and more on NebulaSolver. Whether you’re a student,
                            engineer, or researcher, our resources are designed to simplify complex tasks
                            and accelerate your workflow.
                        </Typography>
                    </CardContent>
                </StyledCard>

                {/* Logic Solver */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/logicblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Logic Solver</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Dive into Boolean logic, Karnaugh maps, and digital circuit design techniques for efficient
                        logic simplification and optimization.
                    </Typography>
                    <LogicBlogPostList />
                </Box>

                {/* Equation Solver */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/blogpostsplain"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Equation Solver</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Learn step-by-step solutions for various mathematical problems, from basic algebraic
                        equations to advanced calculus and differential equations.
                    </Typography>
                    <BlogPostListPlain />
                </Box>

                {/* Linear Algebra */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/matrixblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Linear Algebra</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Perform matrix operations, eigenvalue calculations, and more to solve complex systems
                        in engineering, data science, and beyond.
                    </Typography>
                    <MatrixBlogPostList />
                </Box>

                {/* Bode Plot */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/bodeblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Bode Plot</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Generate and interpret Bode plots to understand frequency response and improve
                        control system performance.
                    </Typography>
                    <BodeBlogPostList />
                </Box>

                {/* Nyquist Plot */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/nyquistblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Nyquist Plot</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Explore system stability using Nyquist diagrams to visualize open-loop transfer
                        functions in the frequency domain.
                    </Typography>
                    <NyquistBlogPostList />
                </Box>

                {/* Nichols Plot */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/nicholsblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Nichols Plot</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Design and analyze feedback loops with Nichols plots for improved system performance
                        and control.
                    </Typography>
                    <NicholsBlogPostList />
                </Box>

                {/* Root Locus */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/rootlocusblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Root Locus</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Visualize how closed-loop poles move as a function of gain, crucial for
                        control system tuning and stability.
                    </Typography>
                    <RootLocusBlogPostList />
                </Box>

                {/* Pole Zero Map */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/polezeromapblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Pole Zero Map</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Map system poles and zeros to gain insights into stability, transient response,
                        and control objectives.
                    </Typography>
                    <PoleZeroMapBlogPostList />
                </Box>

                {/* Step Response */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/stepresponseblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Step Response</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Understand time-domain specifications and transient behavior with step response
                        analyses for system optimization.
                    </Typography>
                    <StepResponseBlogPostList />
                </Box>

                {/* Impulse Response */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/impulseresponseblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Impulse Response</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Examine how systems react to impulses, revealing internal dynamics crucial for
                        robust control design.
                    </Typography>
                    <ImpulseResponseBlogPostList />
                </Box>

                {/* PID Control System */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/feedbackcontrolsystemblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>PID Control System</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Delve into proportional-integral-derivative controllers, tuning methods,
                        and real-world applications for stable system control.
                    </Typography>
                    <FeedbackControlSystemBlogPostList />
                </Box>

                {/* 2D-Truss Analysis */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/feablogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>2D-Truss Analysis</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Model and analyze truss structures for stress, strain, and displacement using
                        finite element analysis (FEA).
                    </Typography>
                    <FeaBlogPostList />
                </Box>

                {/* Beam Analysis */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/beamblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Beam Analysis</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Evaluate bending, shear, and deflection in beam structures to ensure
                        safety and performance in engineering designs.
                    </Typography>
                    <BeamBlogPostList />
                </Box>

                {/* Thermodynamic Diagrams */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/thermoplotsblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Thermodynamic Diagrams</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Plot T-S, P-H, and other thermodynamic diagrams for heat transfer and
                        energy analysis in thermodynamics.
                    </Typography>
                    <ThermoPlotsBlogPostList />
                </Box>

                {/* Battery Management Systems */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/bmsblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Battery Management Systems</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Model, simulate, and analyze battery behavior to maximize performance, safety,
                        and longevity.
                    </Typography>
                    <BmsBlogPostList />
                </Box>

                {/* Electronics */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/circuitblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Electronics</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Design and analyze circuits with advanced solver tools and simulators for
                        more efficient electronic solutions.
                    </Typography>
                    <CircuitBlogPostList />
                </Box>

                {/* Systems Engineering */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/modelblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Systems Engineering</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Develop and simulate system-level models, including FMU-based simulations,
                        for integrated engineering design.
                    </Typography>
                    <ModelBlogPostList />
                </Box>

                {/* Diagrams */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/diagramsblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Diagrams</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Create clear visual representations, from state diagrams to block diagrams,
                        for effective communication and design.
                    </Typography>
                    <DiagramsBlogPostList />
                </Box>

                {/* Gantt Chart */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/ganttblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Gantt Chart</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Plan and track projects with interactive Gantt charts for scheduling,
                        resource management, and collaboration.
                    </Typography>
                    <GanttBlogPostList />
                </Box>

                {/* Stock Analysis */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/stocksblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Stock Analysis</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Utilize advanced computational tools to evaluate trends, forecasts, and
                        potential investments in the stock market.
                    </Typography>
                    <StocksBlogPostList />
                </Box>

                {/* Resume Builder */}
                <Box sx={{ width: '100%' }}>
                    <a
                        href="https://nebulasolver.com/resumeblogposts"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>Resume Builder</StyledTypography>
                    </a>
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#b9b9b9', marginBottom: '1rem' }}>
                        Quickly create a professional resume with our one-step PDF generator,
                        requiring no registration or hidden fees.
                    </Typography>
                    <ResumeBlogPostList />
                </Box>

                {/* Concluding Paragraph */}
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    This comprehensive collection of blog posts is constantly growing. For each category,
                    we provide step-by-step tutorials, real-world examples, and best practices. Whether you're
                    exploring control systems, structural analysis, or advanced mathematics, NebulaSolver aims
                    to simplify complex tasks and empower your projects.
                </Typography>
            </Box>

            {/* Helmet for SEO */}
            <Helmet>
                <title>All Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/blogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/blogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/blogposts" />
                <meta property="og:title" content="How To's - Blog Posts Collection" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/blogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="All Blog Posts" />
                <meta
                    name="twitter:description"
                    content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver."
                />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default BlogPosts;
