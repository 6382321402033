/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ResumeBlogPostList from "./ResumeBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const ResumeBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183', // Update arrow color
                                    padding: '4px', // Reduce padding for the button
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Resume Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Resume Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Explore comprehensive guides on building and optimizing your resume with NebulaSolver. Our tutorials cover best practices in resume design, formatting, and content optimization to help you stand out in a competitive job market.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Learn how to craft compelling resumes that effectively highlight your skills, experience, and accomplishments. Whether you're a recent graduate or a seasoned professional, our step-by-step articles provide actionable tips and real-world examples.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                From choosing the right layout to tailoring your resume for specific industries, our resources cover a wide range of topics. Gain insights on how to utilize modern design tools and strategies that ensure your resume is both eye-catching and professional.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <ResumeBlogPostList />
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    Our extensive collection of resume tutorials is designed to empower job seekers and professionals alike. Continue exploring our guides to discover expert tips, innovative design strategies, and practical advice that will help you create a resume that truly stands out.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Resume Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/resumeblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Resumes using NebulaSolver. Learn best practices in resume design, formatting, and content optimization to make your resume stand out."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/resumeblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/resumeblogposts" />
                <meta property="og:title" content="How To's - Resume Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/resumeblogposts" />
                <meta property="og:description" content="Explore comprehensive articles and tutorials on Resumes using NebulaSolver. Learn best practices in resume design, formatting, and content optimization to make your resume stand out." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Resume Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on Resumes using NebulaSolver. Learn best practices in resume design, formatting, and content optimization to make your resume stand out." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default ResumeBlogPosts;
