/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RootLocusBlogPostList from "./RootLocusBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const RootLocusBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183', // Update arrow color
                                    padding: '4px', // Reduce padding for the button
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Root Locus Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Root Locus Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Explore comprehensive guides on Root Locus analysis that provide step-by-step instructions for plotting the movement of closed-loop poles. Understand how the Root Locus method is used to design and tune feedback control systems for stability and optimal transient response.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Our articles cover both the theoretical foundations and practical applications of Root Locus techniques. Learn how to interpret root locus diagrams, assess gain margins, and adjust controller parameters to achieve desired system performance.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Whether you are new to control systems or an experienced engineer, these tutorials offer valuable insights, real-world examples, and practical tips to help you master Root Locus analysis.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <RootLocusBlogPostList />
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    Continue exploring our extensive collection of Root Locus tutorials and articles. With expert insights and practical examples, NebulaSolver empowers you to optimize your control systems and achieve robust performance.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Root Locus Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/rootlocusblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Root Locus plots using NebulaSolver. Learn how to create and analyze Root Locus plots for system stability and performance."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/rootlocusblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/rootlocusblogposts" />
                <meta property="og:title" content="How To's - Root Locus Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/rootlocusblogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on Root Locus plots using NebulaSolver. Learn how to create and analyze Root Locus plots for system stability and performance."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Root Locus Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on Root Locus plots using NebulaSolver. Learn how to create and analyze Root Locus plots for system stability and performance." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default RootLocusBlogPosts;
