/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MatrixBlogPostList from "./MatrixBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const MatrixBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', mt: 0 }}>
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183',
                                    padding: '4px',
                                    '&:hover': { animation: `${hoverAnimation} 0.3s forwards` }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Matrix Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Linear Algebra Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Learn how to handle matrix addition, subtraction, multiplication, transposition, and inversion.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Discover techniques for solving systems of linear equations, and explore the importance of determinants and matrix rank.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Delve into advanced topics like eigenvalues and eigenvectors to understand matrix diagonalization and its applications in stability analysis and data reduction.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Whether you're a student, researcher, or professional, our comprehensive guides offer practical insights and real-world examples to help you master linear algebra concepts.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <MatrixBlogPostList />
                <Typography variant="body1" sx={{ textAlign: 'left', marginTop: 4, marginBottom: 4, color: '#b9b9b9' }}>
                    Continue exploring our extensive collection of linear algebra tutorials to enhance your understanding of matrix operations and their applications across engineering, data science, and applied mathematics.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Linear Algebra Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/matrixblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Linear Algebra and matrix operations using NebulaSolver. Learn how to handle matrix addition, subtraction, multiplication, transposition, inversion, and advanced topics like eigenvalues and eigenvectors."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/matrixblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/matrixblogposts" />
                <meta property="og:title" content="How To's - Linear Algebra Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/matrixblogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on Linear Algebra and matrix operations using NebulaSolver. Learn how to handle matrix addition, subtraction, multiplication, transposition, inversion, and advanced topics like eigenvalues and eigenvectors."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Linear Algebra Blog Posts" />
                <meta
                    name="twitter:description"
                    content="Explore comprehensive articles and tutorials on Linear Algebra and matrix operations using NebulaSolver. Learn how to handle matrix addition, subtraction, multiplication, transposition, inversion, and advanced topics like eigenvalues and eigenvectors."
                />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default MatrixBlogPosts;
