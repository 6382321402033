/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton, Container } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FeedbackControlBlogPostList from "./FeedbackControlBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const FeedbackControlBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183',
                                    padding: '4px',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 'bold',
                                    letterSpacing: '0.05em',
                                    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    animation: `${rainbowAnimation} 10s ease infinite`,
                                    backgroundSize: '200% 200%',
                                    '@media (max-width:600px)': { fontSize: '1.2em' },
                                    marginBottom: '0px'
                                }}
                            >
                                Feedback Control Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Feedback Control Systems Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Discover in-depth guides and tutorials on designing, analyzing, and optimizing feedback control systems. Our content covers classical PID controllers, adaptive control, and advanced techniques to fine-tune system performance.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Whether you're just starting out or are a seasoned engineer, these articles provide step-by-step instructions, real-world examples, and practical insights to help you master control methodologies and achieve robust system stability.
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Learn how to implement simulation strategies, optimize tuning parameters, and apply best practices that can improve your control system’s responsiveness and reliability across industrial, automotive, and aerospace applications.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <FeedbackControlBlogPostList />
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'left',
                        marginTop: 4,
                        marginBottom: 4,
                        color: '#b9b9b9'
                    }}
                >
                    Our ever-expanding collection of feedback control tutorials is designed to keep you updated with the latest techniques and industry best practices. Explore these comprehensive guides to transform complex control challenges into manageable solutions.
                </Typography>
            </Box>
            <Helmet>
                <title>How To's - Feedback Control Blog Posts</title>
                <link rel="canonical" href="https://nebulasolver.com/feedbackcontrolblogposts" />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on Feedback Control Systems using NebulaSolver. Learn how to design, analyze, and optimize your feedback control systems with real-world examples and best practices."
                />
                <link rel="alternate" hrefLang="en" href="https://nebulasolver.com/feedbackcontrolblogposts" />
                <link rel="alternate" hrefLang="x-default" href="https://nebulasolver.com/feedbackcontrolblogposts" />
                <meta property="og:title" content="How To's - Feedback Control Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/feedbackcontrolblogposts" />
                <meta
                    property="og:description"
                    content="Explore comprehensive articles and tutorials on Feedback Control Systems using NebulaSolver. Learn how to design, analyze, and optimize your feedback control systems with real-world examples and best practices."
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Feedback Control Blog Posts" />
                <meta
                    name="twitter:description"
                    content="Explore comprehensive articles and tutorials on Feedback Control Systems using NebulaSolver. Learn how to design, analyze, and optimize your feedback control systems with real-world examples and best practices."
                />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default FeedbackControlBlogPosts;
